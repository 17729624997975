@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.root {
}

.pageRoot {
  display: flex;
  padding-bottom: 90px;
  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.sectionImages {
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative; /* allow positioning own listing action bar */
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;

  @media (--viewportMedium) {
    padding-bottom: 0; /* No fixed aspect on desktop layouts */
  }
}

.actionBar {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 24px;
  width: calc(100% - 48px);
  color: var(--matterColorNegative);
  background-color: var(--matterColor);
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.imageContent {
  @apply --marketplacePageWidth;

  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
}

.ownListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  @apply --marketplaceH4FontStyles;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--matterColorNegative);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 25px 24px 22px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    object-fit: cover;
    box-shadow: var(--boxShadowListingCard);
  }
}

.viewPhotos {
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentContainer {
  @apply --marketplacePageWidth;
  margin: 48px auto;

  @media (--viewportMedium) {
    margin: 52px auto;
  }
}

.contentWrapper {
  @media (--viewportLarge) {
    /* Layout */
    display: grid;
    align-items: start;
    grid-template-columns: auto 418px;
    grid-gap: 96px;
  }
}

.mainContent {
}

.bookingPanel {
  @media (--viewportLarge) {
    display: block;

    /* Position */
    position: sticky;
    top: calc(var(--topbarHeightDesktop) + 24px);
  }
}

.bookingPanelWrapper {
  @media (--viewportLarge) {
    /* Style */
    background: var(--matterColorLight);
    box-shadow: 2px 6px 12px var(--matterColorAnti);
    border-radius: 8px;

    /* Size */
    padding: 48px;
  }
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.sectionHeading {
  margin-bottom: 48px;

  @media (--viewportMedium) {
    display: flex;
    margin-bottom: 52px;
  }
}

.desktopPriceValue {
  /* Font */
  @apply --marketplaceModalTitleStyles;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.author {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightRegular);
  line-height: 24px;

  margin-top: 6px;
  margin-bottom: 0;
}

.authorNameLink {
  color: var(--marketplaceColor);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
  line-height: 1;
}

.contactLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH5FontStyles;
  margin: 0;
}

.sectionDescription {
  margin-bottom: 48px;

  @media (--viewportMedium) {
    margin-bottom: 52px;
  }
}

.descriptionTitle {
  /* Font */
  @apply --marketplaceH2FontStyles;
  font-weight: var(--fontWeightBold);
  color: var(--matterColor);
  font-size: 26px;

  margin-top: 0;
  margin-bottom: 24px;
}

.sectionFeatures {
  margin-bottom: 48px;

  @media (--viewportMedium) {
    margin-bottom: 52px;
  }
}

.featuresTitle {
  /* Font */
  @apply --marketplaceH2FontStyles;
  font-weight: var(--fontWeightBold);
  color: var(--matterColor);
  font-size: 26px;

  margin-top: 0;
  margin-bottom: 24px;
}

.description {
  margin-top: 0;
  margin-bottom: 0;

  /* Font */
  line-height: 32px;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.sectionMap {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 48px;
  }
}

.locationTitle {
  /* Font */
  @apply --marketplaceH2FontStyles;
  font-weight: var(--fontWeightBold);
  color: var(--matterColor);
  font-size: 26px;

  margin-top: 0;
  margin-bottom: 24px;
}

.sectionReviews {
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.reviewsHeading {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin: 52px 0 20px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 50px 0 26px 0;
  }
}

.sectionHost {
  position: relative;
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.yourHostHeading {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin: 54px 0 23px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 53px 0 27px 0;
  }
}

.sectionInformations {
  margin-bottom: 48px;

  @media (--viewportMedium) {
    margin-bottom: 52px;
  }
}

.informationsTitle {
  /* Font */
  @apply --marketplaceH2FontStyles;
  font-weight: var(--fontWeightBold);
  color: var(--matterColor);
  font-size: 26px;

  margin-top: 0;
  margin-bottom: 24px;
}

.informationsList {
  margin: 0;
}

.informationsListItem {
  @apply --marketplaceBodyFontStyles;
  color: var(--matterColor);
  line-height: 32px;

  margin: 0 0 6px;

  &:last-child {
    margin-bottom: 0;
  }
}

.informationsIcon {
  margin-right: 12px;
}

.sectionWorkOrderLink,
.sectionPurchaseOrder {
  margin-bottom: 48px;

  @media (--viewportMedium) {
    margin-bottom: 52px;
  }
}

.workOrderLinkTitle,
.purchaseOrderTitle {
  /* Font */
  @apply --marketplaceH2FontStyles;
  font-weight: var(--fontWeightBold);
  color: var(--matterColor);
  font-size: 26px;

  margin-top: 0;
  margin-bottom: 24px;
}

.sectionDocuments {
  margin-bottom: calc(48px - 12px);

  @media (--viewportMedium) {
    margin-bottom: calc(52px - 12px);
  }
}

.documentsTitle {
  /* Font */
  @apply --marketplaceH2FontStyles;
  font-weight: var(--fontWeightBold);
  color: var(--matterColor);
  font-size: 26px;

  margin-top: 0;
  margin-bottom: 24px;
}

.documents {
}

.document {
  display: inline-block;

  /* Font */
  @apply --marketplaceBodyFontStyles;
  color: var(--matterColor);

  /* Style */
  background-color: var(--matterColorLight);
  border: 1px solid var(--matterColorNegative);
  box-shadow: 2px 2px 6px var(--matterColorNegative);
  border-radius: 4px;

  /* Width */
  width: fit-content;
  padding: 12px;

  /* Margins */
  margin-top: 0px;
  margin-bottom: 12px;
  margin-left: 0px;
  margin-right: 12px;

  /* Animation */
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    border-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  height: 250px;
  width: 100%;

  background-color: #eee;
  border-radius: 4px;
  padding: 0;
  border: 0;
  cursor: pointer;

  & img {
    border-radius: 4px;
  }

  @media (--viewportMedium) {
    height: 417px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.listingInfo {
  display: flex;
  align-items: center;
}

.viewProfessionals {
}

.viewProfessionalsButton {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightRegular);

  margin: 0;
}

.sectionPriorities {
  margin-bottom: 48px;

  @media (--viewportMedium) {
    margin-bottom: 52px;
  }
}

.prioritiesTitle {
  /* Font */
  @apply --marketplaceH2FontStyles;
  font-weight: var(--fontWeightBold);
  color: var(--matterColor);
  font-size: 26px;
  text-transform: capitalize;

  margin-top: 0;
  margin-bottom: 24px;
}

.sectionQuality {
  margin-bottom: 72px;

  @media (--viewportMedium) {
    margin-bottom: 96px;
  }
}

.qualityTitle {
  /* Font */
  @apply --marketplaceH2FontStyles;
  font-weight: var(--fontWeightBold);
  color: var(--matterColor);
  font-size: 26px;
  text-transform: capitalize;

  margin-top: 0;
  margin-bottom: 24px;
}

.steps {
  /* Layout */
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;

  @media (--viewportMedium) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.singleStep {
  /* Style */
  background: var(--matterColorLight);
  box-shadow: var(--boxShadow);
  padding: 20px 38px 52px;
  border-radius: 2px;
}

.stepLabel {
  /* Font */
  text-transform: capitalize;

  padding: 0;
  margin-bottom: 24px;
  margin-left: -6px;
}

.stepLine {
  /* Position */
  position: relative;
  display: block;

  /* Style */
  height: 8px;
  width: 100%;
  background: var(--matterColorNegative);
  border-radius: 24px;
}

.step {
  /* Position */
  position: relative;
  position: absolute;
  top: 50%;
  z-index: 1;

  /* Style */
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: lightgray;
}

.firstStep {
  left: 0;
  transform: translate(-50%, -50%);
}

.secondStep {
  left: 50%;
  transform: translate(-50%, -50%);
}

.thirdStep {
  right: 0;
  transform: translate(0, -50%);
}

.stepFilled {
  &:after {
    content: '';

    /* Position */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    /* Style */
    height: 9px;
    width: 9px;
    background: var(--marketplaceColor);
    border-radius: 50%;
  }
}

.stepText {
  @apply --marketplaceSmallFontStyles;
  color: var(--matterColorAnti);

  /* Position */
  position: absolute;
  left: 50%;
  top: 24px;
  transform: translate(-50%, 0);

  margin-top: 0;
  margin-bottom: 0;
}

.stepTextFilled {
  color: var(--matterColor);
}

.stepLineFilled {
  position: relative;

  &:after {
    content: '';

    /* Position */
    position: absolute;
    left: 0;
    width: 50%;
    height: 100%;

    /* Style */
    background-color: var(--marketplaceColorLight);
    border-radius: 24px;
  }
}

.stepLineFilledFull {
  &:after {
    width: 100%;
  }
}

.contactButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0 0;
}

.priceContainer {
  display: inline;

  @media (--viewportLarge) {
    display: none;
  }
}

.iconShareContainer {
  /* Layout */
  display: flex;
  align-items: center;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    justify-content: flex-start;
    margin-bottom: 26px;
  }
}

.iconShareButton {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  height: 44px;
  width: 44px;

  /* Style */
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadow);
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background-color: var(--marketplaceColor);

    & .iconShare {
      & * {
        stroke: var(--matterColorLight) !important;
      }
    }
  }
}

.iconShare {
  height: 20px;
  width: 20px;
}
